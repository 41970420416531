<template>
  <div class="p-3">
    <h2>Settings</h2>
    <table class="mb-2 w-100">
      <thead class="border-bottom">
        <tr>
          <th></th>
          <th class="text-left">Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="font-weight-bold pt-2">
            <span>Two factor authentication</span>
          </td>
          <td class="text-left pt-2">
            <span v-text="isMfaEnabled ? 'Enabled' : 'Off'"></span>
          </td>

          <td class="text-right pt-2">
            <template v-if="!isInRemoveState">
              <button
                class="fluent"
                @click="mfaOptionSelector"
                v-text="isMfaEnabled ? 'Remove' : 'Add'"
              ></button>
            </template>
            <template v-else>
              <div>
                <button
                  class="fluent ms-bgColor-sharedRed20 ms-fontColor-gray10 mr-2"
                  @click="removeMfa"
                  v-text="'Remove'"
                ></button>
                <button
                  class="fluent"
                  @click="removeState = false"
                  v-text="'Cancel'"
                ></button>
              </div>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data: () => {
    return {
      removeState: false
    };
  },
  methods: {
    ...mapActions(["getFromApi", "postJsonToApi"]),
    mfaOptionSelector() {
      if (this.isMfaEnabled) this.removeState = true;
      else this.$router.push("settings/two-factor-authentication"); // should be a state thing.
    },
    async removeMfa() {
      await this.postJsonToApi({
        url: "/settings/remove-mfa",
        mutation: "setSettings"
      });
    }
  },
  computed: {
    isInRemoveState() {
      return this.removeState;
    },
    isMfaEnabled() {
      return this.$store.state.mfaEnabled;
    }
  },
  async beforeMount() {
    await this.getFromApi({
      url: "/settings/user-settings",
      mutation: "setSettings"
    });
  }
};
</script>
